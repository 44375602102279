<div class="desktop-grid">
    <div class="mat-elevation-z8">
        <div class="panel-grid">
            <div class="panel-item-grid">
                <app-home-link></app-home-link>
            </div>

            <div></div>

            <div class="panel-item-grid">
                <app-help-link></app-help-link>
                <app-settings-link></app-settings-link>
            </div>
        </div>
    </div>

    <main class="content-scroll-pane">
        <div class="content-scroll-pane-content">
            <app-download-handler></app-download-handler>
            <router-outlet></router-outlet>
        </div>
    </main>
</div>
